<template>
    <div
        :class="`wagon ${carroConvoglio.bloccato ? 'wagon-blocked' : ''}`"
        :style="`width:${100*length/visibleRailLength}%;left:${100*initPosition/visibleRailLength}%`"
        v-on:click="wagonOnClick"
    >
       <div class="wagon-ctnr"> 
             <div class="uties-ctnr" v-if="filterView && (filterView==='PARTENZA' || filterView==='ALL')">
                <div
                    :class="`uti ${utiCarro.remainOnBoardScarico ? 'uti-rob' : ''}`"
                    v-for="(utiCarro, idx) in orderedImportUties" 
                    :key="utiCarro.id" 
                    :title="getUtiCode(utiCarro)"
                    :style="`width:${calcUtiCompWith(carroConvoglio, 'IMPORT')}%;background:${getUtiStateColor(utiCarro)};${calcBorder(idx)};`"
                >
                    <v-icon class="uti-icon">mdi-download</v-icon>&nbsp; 
                    <v-icon 
                        v-if="getUtiDangerousFlag(utiCarro)" 
                        class="uti-danger-icon"
                    >
                        mdi-alert
                    </v-icon>                               
                    <br />
                    <div class="uti-text">
                        <span class="white--text">
                            <b>{{ isConvenzionale ? `${getUtiCode(utiCarro)}: ${getUtiPackagesNumber(utiCarro)}` : getUtiCode(utiCarro) }}</b>
                        </span>
                    </div> 
                </div>
            </div>   

            <div class="uties-ctnr" v-if="filterView && filterView==='ARRIVO'">           
            </div>
 
            <div class="uties-ctnr" v-if="filterView &&  (filterView==='ARRIVO' || filterView==='ALL')">
                <div
                    :class="`uti ${utiCarro.remainOnBoardScarico ? 'uti-rob' : ''}`"
                    v-for="(utiCarro, idx) in orderedExportUties" 
                    :key="utiCarro.id" 
                    :title="getUtiCode(utiCarro)"
                    :style="`width:${calcUtiCompWith(carroConvoglio, 'EXPORT')}%;background:${getUtiStateColor(utiCarro)};${calcBorder(idx)};`"
                >
                    <v-icon class="uti-icon">mdi-upload</v-icon>&nbsp;
                    <v-icon 
                        v-if="getUtiDangerousFlag(utiCarro)" 
                        class="uti-danger-icon"
                    >
                        mdi-alert
                    </v-icon>                 
                    <br />
                    <div class="uti-text">
                        <span class="white--text">
                            <b>{{ isConvenzionale ? `${getUtiCode(utiCarro)}: ${getUtiPackagesNumber(utiCarro)}` : getUtiCode(utiCarro) }}</b>
                        </span>
                    </div> 
                </div>
            </div>
      </div>   

        <div 
            v-if="isConvenzionale"
            :title="licensePlate" 
            class="wagon-details conventional-wagon-details"
        >
            <span> 
                {{ licensePlate }}
            </span>
        </div>
        <div 
            v-else
            :title="licensePlate" 
            class="wagon-details"
        >
            <span> 
                {{ licensePlate }}
            </span>
        </div>  
    </div>   
</template>

<script>
import convoyVisualizationMixins from '../mixins/convoyVisualizationMixins';

export default {
    name: "convoyWagon",  
    props: {
        carroConvoglio: {
            type: Object,
            required: true
        },
        visibleRailLength: {
            type: Number,
            default: 200
        },
        visibleRailInitPos: {
            type: Number,
            default:0
        },
        filterView: {
            type: String,
            required: true  
        }
    },
    mixins: [convoyVisualizationMixins],
    data() {
        return {
            detailOpened: false
        };
    },
    methods: {
        wagonOnClick () {
          this.$eventBus.$emit('wagonSelected', this.carroConvoglio);
        },
    },
    computed: {
        isConvenzionale(){
            return this.isCarroConvenzionale(this.carroConvoglio);
        }, 
        unchargedImportUtiNumber() {
            return this.getUnchargedImportUtiNumber(this.carroConvoglio);           
        },
        unchargedExportUtiNumber() {
            return this.getUnchargedExportUtiNumber(this.carroConvoglio);            
        },
        orderedImportUties() {
            return this.getOrderedImportUties(this.carroConvoglio);           
        },
        orderedExportUties() {
            return this.getOrderedExportUties(this.carroConvoglio);
        },
        initPosition() {
            return this.carroConvoglio.posizioneBinario - this.visibleRailInitPos;
        },
        utiCount() {
            return this.getCarroUtiCount(this.carroConvoglio);
        },
        iso() {
            return this.getCarroIso(this.carroConvoglio);
        },
        imo() {
            return this.getCarroIso(this.carroConvoglio);
        },
        length() {
            return this.getCarroLength(this.carroConvoglio);
        },
        licensePlate() {
            return this.getCarroLicensePlate(this.carroConvoglio);
        }
    }
}
</script>

<style scoped>
.wagon{
    z-index:2;
    position:absolute;
    height: 118px;
    margin-top: 36px;
    border-right: 2px dashed #000000;
    background: rgba(105, 105 , 105, 0.5);
    overflow: visible;
}
.wagon-blocked{
    background-image: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 5px,
      rgb(196, 196, 192) 5px,
      rgb(196, 196, 192) 10px) !important;
}
.wagon-ctnr{
    height: 80%;
    width: 100%;
}
.uties-ctnr{
    height: 50%;
    width: 100%;
    float: left;
}
.uti{ 
    height: 100%;  
    float: left;
    border-color: #333333;
    border-style: dashed;
    font-size: 0.5rem;  
    overflow: hidden;
}
.uti-number{
    height: 100%;
    width: 50%;
    float: left;
    text-align: center;  
    font-size: 0.8rem;  
    overflow: hidden;
}
.uti-rob{
    background-image: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 5px,
      rgb(196, 196, 192) 5px,
      rgb(196, 196, 192) 10px) !important;
}
.uti i{
    font-size: 0.8rem;
    font-weight: bold; 
    float: right;
}
.uti-danger-icon{
    color:red;
}
.uti-text{
    text-align: center;
}
.uti-text > span{
   margin: 0 auto;  
   word-wrap: break-word;
}   
.uti-text b{
    word-wrap: break-word;
}
.wagon-details{
    height: 20%;
    width: 100%;
    float: left;
    background: #615c59;
    overflow: hidden;
    text-align: center;
}
.wagon-details > span{
    font-size: 0.6rem;
    font-weight: bold;
    line-height: 9px;
    color: #f9fafb;
    text-align: center;
    vertical-align: middle;
}
.conventional-wagon-details{
    background: #aca397 !important;
}
.conventional-wagon-details > span{
    color: #615c59 !important;
}
</style>
<template>
  <div class="grey lighten-5">
    <v-row class="mb-6 rail-row" no-gutters>
      <v-col cols="12">
        <div class="rail-label">
          {{ railNumber }}
        </div>
        <div class="rail" :style="`width:100%;min-width:600px;`"></div>
        <convoy-wagon
          v-for="carroConvoglio in carriConvoglio.filter(c => c.visitaCarro.targa!=='0')"
          :key="carroConvoglio.id"
          :carroConvoglio="carroConvoglio"
          :visibleRailLength="visibleRailLength"
          :visibleRailInitPos="railVisibleSections[0] - lengthInterval"
          :filterView="filterView"
        />
        <div class="rail-distance-cntr" :style="`width:100%;min-width:600px;`">
          <div v-for="index in railVisibleSections" :key="index" class="rail-distance" :style="`width:${(100 * lengthInterval) / visibleRailLength}%;`">
            {{ index }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import convoyWagon from "./ConvoyWagon";
import convoyVisualizationMixins from "../mixins/convoyVisualizationMixins";

export default {
  name: "convoySpatialVisualization",
  components: {
    convoyWagon,
  },
  props: {
    lengthInterval: {
      type: Number,
      default: 10,
    },
    convoglio: {
      type: Object,
      default: () => {},
    },
    filterView: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mixins: [convoyVisualizationMixins],
  mounted() {
    console.log("this.filterView: ", this.filterView);
  },
  computed: {
    railId() {
      return this.convoglio && this.convoglio.binario ? this.convoglio.binario.id : "";
    },
    railNumber() {
      return this.convoglio && this.convoglio.binario ? `B. ${this.convoglio.binario.numero}` : "";
    },
    carriConvoglio() {
      let pos = this.convoglio.posizioneBinario;
      this.convoglio.listCarri = this._.orderBy(this.convoglio.listCarri, [(cc) => cc.posizione]);
      this.convoglio.listCarri.forEach((cc) => {
        cc.dialog = false;
        cc.posizioneBinario = pos;
        cc.idConvoglio = this.convoglio.id;
        pos += this.getCarroLength(cc);
      });
      return this.convoglio.listCarri;
    },
    convoyLength() {
      return this.getConvoyLength();
    },
    railVisibleSections() {
      return this.getRailVisibleSections();
    },
    visibleRailLength() {
      const railVisibleSections = this.getRailVisibleSections();
      return railVisibleSections[railVisibleSections.length - 1] - railVisibleSections[0] + this.lengthInterval;
    },
  },
  methods: {
    getRailVisibleSections() {
      const initPos = this.convoglio.posizioneBinario;
      const idxInitPos = initPos == 0 ? this.lengthInterval : initPos % this.lengthInterval == 0 ? initPos : initPos + (initPos % this.lengthInterval);
      const railSections = [];
      for (let i = idxInitPos; i <= this.getConvoyLength() + idxInitPos + this.lengthInterval; i += this.lengthInterval) {
        railSections.push(i);
      }
      return railSections;
    },
  },
};
</script>

<style scoped>
.rail-row {
  height: 160px;
  position: relative;
}
.rail {
  z-index: 0;
  position: absolute;
  height: 120px;
  margin-top: 35px;
  border: 2px solid #aaaaaa;
}
.rail-label {
  position: absolute;
  left: 0px;
}
.rail[selected="selected"] {
  background: #d3d3d3;
}
.rail-distance-cntr {
  z-index: 1;
  position: absolute;
  margin-top: 38px;
}
.rail-distance {
  height: 147px;
  margin-top: -30px;
  float: left;
  border-right: 1px solid #aaaaaa;
  text-align: right;
  color: rgb(99, 99, 99);
  padding-right: 1px;
  font-size: 1rem;
}
</style>
